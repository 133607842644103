<template>
  <v-banner
    v-if="section && isDisplayed"
    :sticky="stickySection"
    class="mb-4 section-banner"
    :color="$vuetify.theme.currentTheme.background"
  >
    <div class="text-h5 d-flex">
      <katex-md
        v-if="section.attributes.name"
        :expr="section.attributes.name"
      />

      <v-spacer />
      <v-btn
        icon
        @click="stickySection = !stickySection"
      >
        <v-icon
          small
          v-html="stickySection ? 'mdi-pin-off-outline':'mdi-pin-outline'"
        />
      </v-btn>
    </div>


    <katex-md
      v-if="section.attributes.description"
      :expr="section.attributes.description"
    />
  </v-banner>
</template>

<script>

import KatexMd from "../../../katex-md.vue"

export default {
  name: "SectionInExam",
  components: { KatexMd },
  props: {
    section: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    stickySection: false,
  }),

  computed: {
    isDisplayed() {
      return this.section?.attributes?.name || this.section?.attributes?.description
    },
  },
}
</script>

<style>
  .section-banner .v-banner__wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
</style>
