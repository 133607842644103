<template>
  <div
    v-if="exercise"
    class="mb-12 mt-5"
  >
    <section-in-exam
      :section="section"
      v-if="showSectionInfo"
    />

    <div
      class="mb-10"
      v-if="exercise.attributes && !exercise.isImplicit"
    >
      <div
        class="card-subtitle grey--text mt-2"
        v-if="position"
      >
        <b>{{ subtitle }}</b>
      </div>
      <katex-md
        class="text-h6"
        v-if="exercise.attributes.name"
        :expr="exercise.attributes.name"
      />

      <katex-md
        v-if="exercise.attributes.description"
        :expr="exercise.attributes.description"
      />
    </div>

    <v-lazy
      v-for="(question, index) in questions"
      v-if="question.type"
      :key="`${question.uuid}-${question.id}`"
      :options="{threshold: .5}"
      :min-height="question.minHeight"
      transition="fade-transition"
      class="mb-12"
    >
      <question-normal
        class="mb-10"
        :question="question"
        :quiz="quiz"
        :index="question.position || exercise.childIndex(index, question)"
        :preview-mode="previewMode"
        :quiz-instance="quizInstance"
        :questions-count="exercise.indexableChildrenCount"
        @questionAnswered="$emit('questionAnswered')"
        :correction-mode="correctionMode"
        :show-score="showScore && (quiz.show_scores || currentUser.teacher)"
        :user-id="userId"
        :allow-navigation="quiz.allow_questions_navigation"
        :manual-correction-mode="manualCorrectionMode"
        :exercise="exercise"
        :quiz-score="quizScore"
        :quizzes-attempt-summary="quizzesAttemptSummary"
      >
        <template #grader="{answer, question}">
          <slot
            name="grader"
            :answer="answer"
            :question="question"
          />
        </template>
        <template #feedbackEditor="{answer, question, questionComment, hasQuestionComment }">
          <slot
            name="feedbackEditor"
            :answer="answer"
            :question="question"
            :question-comment="questionComment"
            :has-question-comment="hasQuestionComment"
          />
        </template>
      </question-normal>
    </v-lazy>
  </div>
</template>

<script>
import QuestionNormal from "../../../questions/question_normal.vue"
import KatexMd from "../../../katex-md.vue"
import { mapGetters } from "vuex"
import SectionInExam from "./sectionInExam.vue"

export default {
  name: "ExerciseStudent",
  components: { SectionInExam, KatexMd, QuestionNormal },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
    quizInstance: {
      type: Object,
      default: null,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    correctionMode: {
      type: Boolean,
      default: false,
    },
    manualCorrectionMode: {
      type: Boolean,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },
    questionsFilter: {
      type: Function,
      default: (q) => true,
    },
    showSectionInfo: {
      type: Boolean,
      default: false,
    },
    showScore: {
      type: Boolean,
      default: true,
    },
    quizScore: {
      type: Object,
      default: null,
    },
    quizzesAttemptSummary: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    questions() {
      if( !this.exercise || !this.exercise.children ) return []
      return this.exercise.children.filter(this.questionsFilter)//.map( question => {
      //   return {
      //     ...question.attributes,
      //     allow_multiple_choices: question.allowMultipleChoices || question.attributes.allow_multiple_choices,
      //     choices: question.children.map(c => ({ ...c.attributes })),
      //   }
      // })
    },
    section() {
      return this.exercise?.section
    },
    position() {
      return this.exercise.attributes.position
    },
    subtitle() {
      if (!this.position) return
      return `Exercice ${this.position + 1}`
    },
  },
}
</script>

<style scoped>

</style>
